<template>
  <div>
    <div class="bg"></div>
    <div class="content">
      <div class="container">
        <h1 class="container">Dev</h1>
        <span class="text">elopment</span>
        <h1 class="container" style="margin-left: 2px">Partner</h1>
      </div>
      <p style="font-size: larger">
        Ohjelmointi ja ohjelmistokehitys yli viidentoista vuoden kokemuksella.
      </p>
      <div style="margin-top: 30px; text-align: center">
        <a href="https://www.linkedin.com/in/hannu-saarinen-7737333a/">Linkedin</a>
      </div>
      <div>
        <a href="mailto: info@devpartner.fi">Ota yhteyttä</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainView'
}
</script>

<style scoped>
.container {
  display: inline-block;
  font-size: 150%;
}
.h1 {
  font-size: small;
}
.content {
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  background: rgb(255, 255, 255); /* Fallback for older browsers without RGBA-support */
  background: rgba(255, 255, 255, 0.88);
  margin: 7% 5% 10% 5%;
  padding: 5%;
  box-shadow: 5px 5px 5px;
  min-width: 400px;
}
.bg {
  background-image: url('~@/assets/tausta.jpg');
  height: 100%;
  width: 100%;
  background-size: cover;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: -1;
}

.text {
  display: inline-block;
  font-size: large;
  animation: change 2s normal 0s;
}

@keyframes change {
  0% { left: 0px; top: 0; transform: translate(-1000%, 0%); }
  100% { left: 0; top: 0; transform: translate(-0%, 0%); }
}
</style>
